var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c(
          "c-card",
          { staticClass: "cardClassDetailInfo", attrs: { noHeader: true } },
          [
            _c("template", { slot: "card-detail" }, [
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("c-label-text", {
                    attrs: { title: "자재명", value: _vm.review.materialName },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("c-label-text", {
                    attrs: { title: "자재코드", value: _vm.review.materialCd },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical",
      },
      [
        _c("c-tab", {
          attrs: {
            type: "vertical",
            tabItems: _vm.tabItems,
            height: _vm.tabheight,
          },
          on: {
            "update:height": function ($event) {
              _vm.tabheight = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (tab) {
                return [
                  _c(tab.component, {
                    tag: "component",
                    attrs: {
                      popupParam: _vm.popupParam,
                      review: _vm.review,
                      disabled: _vm.disabled,
                      fileLength: _vm.fileLength,
                    },
                    on: {
                      "update:popupParam": function ($event) {
                        _vm.popupParam = $event
                      },
                      "update:popup-param": function ($event) {
                        _vm.popupParam = $event
                      },
                      "update:review": function ($event) {
                        _vm.review = $event
                      },
                      "update:disabled": function ($event) {
                        _vm.disabled = $event
                      },
                      "update:fileLength": function ($event) {
                        _vm.fileLength = $event
                      },
                      "update:file-length": function ($event) {
                        _vm.fileLength = $event
                      },
                    },
                  }),
                ]
              },
            },
          ]),
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }